<template>
  <v-form :readonly="value.id > 0 && !canEditSetupTables && !me.is.driverTableOnly">
    <v-container>
      <v-row>
        <v-col cols="12" md="12">
          <v-btn @click="cancel(false)"> Cancel </v-btn>
          <v-btn
            v-if="canEditSetupTables || me.is.driverTableOnly"
            class="float-right"
            color="green"
            dark
            @click="onSubmit"
          >
            save
          </v-btn>
          <v-btn
            v-if="me.is.superAdmin && value.synced"
            class="float-right mr-2"
            color="primary"
            @click="hide()"
            :disabled="value.cannotDelete"
          >
            <v-icon class="mr-2" small>mdi-{{ value.hidden ? 'eye' : 'eye-off' }}</v-icon>
            {{ value.hidden ? 'Unhide' : 'Hide' }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-alert v-if="value.synced" outlined type="info" text>
        This Driver is synced with EZRouting. Some fields will not be able to be modified here.
      </v-alert>
      <v-alert v-else-if="!value.id && isRoutingClient" outlined type="info" text>
        This Driver will only be avilable in EZActivityTrips. If you would like this Driver to be available in
        EZRouting, please add them there first and they will be synced with EZActivityTrips.
      </v-alert>
      <v-expansion-panels accordion mandatory>
        <v-expansion-panel>
          <v-expansion-panel-header>General</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" md="5" class="pb-0">
                <v-text-field
                  :readonly="value.synced"
                  :value="value.firstName"
                  v-model.trim="$v.form.firstName.$model"
                  label="First Name"
                  required
                  hint="*Required"
                  :persistent-hint="!value.id"
                  :error-messages="handleErrors($v.form.firstName)"
                  @blur="$v.form.firstName.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" class="pb-0">
                <v-text-field
                  :readonly="value.synced"
                  :value="value.lastName"
                  v-model.trim="$v.form.lastName.$model"
                  label="Last Name"
                  required
                  hint="*Required"
                  :persistent-hint="!value.id"
                  :error-messages="handleErrors($v.form.lastName)"
                  @blur="$v.form.lastName.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="pb-0">
                <v-checkbox
                  :readonly="value.synced"
                  :value="value.active"
                  v-model="$v.form.active.$model"
                  label="Active"
                  @change="$v.form.active.$touch()"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="10" class="pb-0">
                <v-combobox
                  :value="value.email"
                  v-model.trim="$v.form.email.$model"
                  :items="userEmails"
                  label="Email"
                  @blur="$v.form.email.$touch()"
                  @input.native="$v.form.email.$model = $event.srcElement.value"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" class="pb-0">
                <v-select
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="Base Location"
                  :value="value.baseLocationId"
                  v-model="$v.form.baseLocationId.$model"
                  :items="locations"
                  item-text="name"
                  item-value="id"
                  outlined
                  class="mb-0"
                  :error-messages="handleErrors($v.form.baseLocationId)"
                  @blur="$v.form.baseLocationId.$touch()"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="4" class="pb-0">
                <v-combobox
                  :value="value.zone"
                  v-model.trim="$v.form.zone.$model"
                  :items="zoneItems"
                  label="Zone"
                  @blur="$v.form.zone.$touch()"
                  @input.native="$v.form.zone.$model = $event.srcElement.value"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" class="pb-0">
                <v-select
                  :menu-props="{ bottom: true, offsetY: true }"
                  :label="secondaryLocationLabel"
                  :value="value.secondaryLocationId"
                  v-model="$v.form.secondaryLocationId.$model"
                  :items="locations"
                  item-text="name"
                  item-value="id"
                  outlined
                  class="mb-0"
                  :error-messages="handleErrors($v.form.secondaryLocationId)"
                  @blur="$v.form.secondaryLocationId.$touch()"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" class="pb-0">
                <v-text-field
                  :value="assignedVehicles"
                  label="Assigned Vehicle(s)"
                  hint="You can assign Drivers to Vehicles in the Vehicles Table"
                  persistent-hint
                  readonly
                ></v-text-field>
                <v-alert v-if="upcomingAssignments?.driverId" class="mb-0" outlined type="warning" text>
                  This driver has an upcoming assignment with Trip Request #{{ upcomingAssignments.tripRequestId }} on
                  {{ convertDateFormat(upcomingAssignments.vehPickupDate + ' ' + upcomingAssignments.vehPickupTime) }}
                </v-alert>
              </v-col>
              <v-col cols="12" md="4" class="pb-0">
                <v-select
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="Vehicle Preference"
                  :value="value.vehiclePreference"
                  v-model="$v.form.vehiclePreference.$model"
                  :items="vehiclePreferences"
                  item-text="text"
                  item-value="value"
                  outlined
                  class="mb-0"
                  :error-messages="handleErrors($v.form.vehiclePreference)"
                  @blur="$v.form.vehiclePreference.$touch()"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" class="pb-0">
                <v-combobox
                  :value="value.designation"
                  v-model.trim="$v.form.designation.$model"
                  :items="designationItems"
                  label="Designation"
                  :readonly="value.synced"
                  @blur="$v.form.designation.$touch()"
                  @input.native="$v.form.designation.$model = $event.srcElement.value"
                ></v-combobox>
              </v-col>
              <!-- <v-col cols="12" md="6" class="pb-0">
              <v-combobox
                :value="value.position"
                v-model.trim="$v.form.position.$model"
                :items="positionItems"
                label="Position"
                @blur="$v.form.position.$touch()"
                @input.native="$v.form.position.$model = $event.srcElement.value"
              ></v-combobox>
            </v-col> -->
            </v-row>
            <v-row>
              <v-col cols="12" md="5" class="pb-0">
                <v-text-field
                  :value="value.trips"
                  v-model.trim="$v.form.trips.$model"
                  label="Total Trips"
                  type="number"
                  min="0"
                  @blur="$v.form.trips.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" class="pb-0">
                <v-text-field
                  :value="value.hours"
                  v-model.trim="$v.form.hours.$model"
                  label="Total Hours"
                  type="number"
                  min="0"
                  @blur="$v.form.hours.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- <v-row>
            <v-col cols="12" md="6" class="pb-0">
              <v-text-field
                :value="value.classCD"
                v-model.trim="$v.form.classCD.$model"
                label="Class Cd"
                :persistent-hint="!value.id"
                :error-messages="handleErrors($v.form.classCD)"
                @blur="$v.form.classCD.$touch()"
              ></v-text-field>
            </v-col>
          </v-row> -->
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Addresses</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" md="12" v-for="(address, i) in addresses" :key="i">
                <v-card>
                  <v-card-text>
                    <address-form
                      :ref="`aform-${i}`"
                      :value="address"
                      @input="handleInputAddress($event, i)"
                      :showLatLng="false"
                      multiple
                      :disabled="address.deleted || isRoutingClient"
                      @handlePrimary="handlePrimaryAddress(i, $event)"
                    ></address-form>
                  </v-card-text>
                  <v-card-actions class="px-6">
                    <v-btn v-if="address.deleted" @click="deleteAddress(i)"><v-icon>mdi-undo</v-icon> Undo</v-btn>
                    <v-btn v-else color="error" @click="deleteAddress(i)"><v-icon>mdi-delete</v-icon> Delete</v-btn>
                    <v-spacer></v-spacer>
                    <div class="text-subtitle2">#{{ i + 1 }}</div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-btn color="primary" @click="addAddress" :disabled="isRoutingClient"
                  ><v-icon>mdi-plus</v-icon> Add Address</v-btn
                >
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Contacts</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" md="12" v-for="(contact, i) in contacts" :key="i">
                <v-card>
                  <v-card-text>
                    <contact-form
                      :ref="`cform-${i}`"
                      :value="contact"
                      :disabled="contact.deleted || isRoutingClient"
                      @input="handleInputContact($event, i)"
                      @handlePrimary="handlePrimaryContact(i, $event)"
                    ></contact-form>
                  </v-card-text>
                  <v-card-actions class="px-6">
                    <v-btn v-if="contact.deleted" @click="deleteContact(i)"><v-icon>mdi-undo</v-icon> Undo</v-btn>
                    <v-btn v-else color="error" @click="deleteContact(i)" :disabled="isRoutingClient">
                      <v-icon>mdi-delete</v-icon> Delete
                    </v-btn>
                    <v-spacer></v-spacer>
                    <div class="text-subtitle2">#{{ i + 1 }}</div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-btn color="primary" @click="addContact" :disabled="isRoutingClient">
                  <v-icon>mdi-plus</v-icon> Add Contact
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Rates</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" md="4" class="pb-0">
                <v-text-field
                  :value="value.payRate"
                  v-model.trim="$v.form.payRate.$model"
                  label="Rate"
                  type="number"
                  min="0"
                  prepend-icon="mdi-currency-usd"
                  :error-messages="handleErrors($v.form.payRate)"
                  @blur="$v.form.payRate.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="pb-0">
                <v-text-field
                  :value="value.otRate"
                  v-model.trim="$v.form.otRate.$model"
                  label="OT Rate"
                  type="number"
                  min="0"
                  prepend-icon="mdi-currency-usd"
                  :error-messages="handleErrors($v.form.otRate)"
                  @blur="$v.form.otRate.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="pb-0">
                <v-text-field
                  :value="value.otherRate"
                  v-model.trim="$v.form.otherRate.$model"
                  label="Other Rate"
                  type="number"
                  min="0"
                  prepend-icon="mdi-currency-usd"
                  :error-messages="handleErrors($v.form.otherRate)"
                  @blur="$v.form.otherRate.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Identification</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" md="12" class="pb-0">
                <v-radio-group
                  v-model.trim="$v.form.fulltime.$model"
                  :value="value.fulltime"
                  row
                  @blur="$v.form.fulltime.$touch()"
                >
                  <v-radio label="Full-Time" :value="1"></v-radio>
                  <v-radio label="Part-Time" :value="0"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" class="pb-0">
                <v-text-field
                  :readonly="value.synced"
                  :value="value.localId"
                  v-model.trim="$v.form.localId.$model"
                  label="ID"
                  :persistent-hint="!value.id"
                  :error-messages="handleErrors($v.form.localId)"
                  @blur="$v.form.localId.$touch()"
                ></v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" md="6" class="pb-0">
                <v-text-field
                  :readonly="value.synced"
                  :value="value.SSN"
                  v-model.trim="$v.form.SSN.$model"
                  label="SSN"
                  :persistent-hint="!value.id"
                  :error-messages="handleErrors($v.form.SSN)"
                  @blur="$v.form.SSN.$touch()"
                ></v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" md="4" class="pb-0">
                <date-picker label="Date of Birth" v-model="$v.form.dob.$model" :readonly="value.synced"></date-picker>
              </v-col>
              <v-col cols="12" md="4" class="pb-0">
                <date-picker
                  label="Hire/Sen Date"
                  v-model="$v.form.hireAt.$model"
                  :readonly="value.synced"
                ></date-picker>
              </v-col>
              <v-col cols="12" md="4" class="pb-0">
                <v-checkbox
                  :value="value.retirement"
                  v-model="$v.form.retirement.$model"
                  label="Retirement"
                  required
                  @change="$v.form.retirement.$touch()"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-subheader>Supplemental Driver Dates</v-subheader>
            <v-row>
              <v-col cols="12" md="4" class="pb-0">
                <date-picker label="Temp/Perm" v-model="$v.form.tempDate.$model"></date-picker>
              </v-col>
              <v-col cols="12" md="4" class="pb-0">
                <date-picker label="Sub" v-model="$v.form.subDate.$model"></date-picker>
              </v-col>
              <v-col cols="12" md="4" class="pb-0">
                <date-picker label="Application" v-model="$v.form.applicationDate.$model"></date-picker>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Availability Preferences / Restrictions</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" md="4" class="pb-0" v-if="config.prefLabel1.length">
                <div class="text-subtitle-2 ml-0">{{ config.prefLabel1 }}</div>
                <v-radio-group
                  v-model.trim="$v.form.prefAM.$model"
                  :value="value.prefAM"
                  row
                  @blur="$v.form.prefAM.$touch()"
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="4" class="pb-0" v-if="config.prefLabel2.length">
                <div class="text-subtitle-2 ml-0">{{ config.prefLabel2 }}</div>
                <v-radio-group
                  v-model.trim="$v.form.prefMidDay.$model"
                  :value="value.prefMidDay"
                  row
                  @blur="$v.form.prefMidDay.$touch()"
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="4" class="pb-0" v-if="config.prefLabel3.length">
                <div class="text-subtitle-2 ml-0">{{ config.prefLabel3 }}</div>
                <v-radio-group
                  v-model.trim="$v.form.prefPM.$model"
                  :value="value.prefPM"
                  row
                  @blur="$v.form.prefPM.$touch()"
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="4" class="pb-0" v-if="config.prefLabel4.length">
                <div class="text-subtitle-2 ml-0">{{ config.prefLabel4 }}</div>
                <v-radio-group
                  v-model.trim="$v.form.prefOvernight.$model"
                  :value="value.prefOvernight"
                  row
                  @blur="$v.form.prefOvernight.$touch()"
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="4" class="pb-0" v-if="config.prefLabel5.length">
                <div class="text-subtitle-2 ml-0">{{ config.prefLabel5 }}</div>
                <v-radio-group
                  v-model.trim="$v.form.prefWeekend.$model"
                  :value="value.prefWeekend"
                  row
                  @blur="$v.form.prefWeekend.$touch()"
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="4" class="pb-0" v-if="config.prefLabel6.length">
                <div class="text-subtitle-2 ml-0">{{ config.prefLabel6 }}</div>
                <v-radio-group
                  v-model.trim="$v.form.prefNonSchool.$model"
                  :value="value.prefNonSchool"
                  row
                  @blur="$v.form.prefNonSchool.$touch()"
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4" class="pb-0">
                <v-text-field
                  :value="value.regHoursWeek"
                  v-model.trim="$v.form.regHoursWeek.$model"
                  label="Reg Hours/Week"
                  type="number"
                  min="0"
                  :error-messages="handleErrors($v.form.regHoursWeek)"
                  @blur="$v.form.regHoursWeek.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="pb-0">
                <v-text-field
                  :value="value.regHoursDay"
                  v-model.trim="$v.form.regHoursDay.$model"
                  label="Reg Hours/Day"
                  type="number"
                  min="0"
                  :error-messages="handleErrors($v.form.regHoursDay)"
                  @blur="$v.form.regHoursDay.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Comments / Indicators</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" md="12" class="pb-0">
                <v-textarea
                  :value="value.comments"
                  v-model.trim="$v.form.comments.$model"
                  outlined
                  rows="1"
                  auto-grow
                  label="Comments"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" class="pb-0">
                <v-combobox
                  :value="value.indicators"
                  v-model.trim="$v.form.indicators.$model"
                  :items="indicatorItems"
                  label="Indicators"
                  outlined
                  multiple
                  chips
                  @blur="$v.form.indicators.$touch()"
                ></v-combobox>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>

    <v-container>
      <v-row>
        <v-col cols="12" md="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="10">
                  <v-text-field
                    v-model.trim="driverLogNotes"
                    :value="driverLogNotes"
                    label="Enter comment concerning driver to post to driver log"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-btn color="primary" class="mt-2" @click="postDriverLog">Save</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';
import { SAVE_DRIVER_LOG } from '@/store/modules/DriverLog/actions';
import AddressForm from '@/components/AddressForm.vue';
import ContactForm from '@/components/ContactForm.vue';
import DatePicker from '@/components/DatePicker';
import { handleErrors } from '@/util';

export default {
  mixins: [validationMixin],
  components: { AddressForm, ContactForm, DatePicker },
  props: {
    value: Object,
    config: Object,
    zoneItems: Array,
    designationItems: Array,
    positionItems: Array,
    indicatorItems: Array,
  },
  data() {
    return {
      handleErrors,
      panel: [0],
      id: null,
      form: this.newDriver,
      addresses: [],
      contacts: [],
      upcoming: {},
      vehiclePreferences: [
        { text: 'No Preference', value: 0 },
        { text: 'Regular Only', value: 1 },
        { text: 'Special Needs Only', value: 2 },
        { text: 'Either (Prefer Regular)', value: 3 },
        { text: 'Either (Prefer Special Needs)', value: 4 },
      ],
      driverLogNotes: '',
      search: null,
      saved: false,
    };
  },
  validations: {
    form: {
      firstName: { required },
      lastName: { required },
      email: {},
      zone: {},
      baseLocationId: {},
      secondaryLocationId: {},
      assignedVehicleIds: {},
      vehiclePreference: {},
      designation: {},
      trips: {},
      hours: {},
      position: {},
      classCD: {},
      fulltime: {},
      localId: {},
      SSN: {},
      dob: {},
      hireAt: {},
      serviceTime: {},
      retirement: {},
      tempDate: {},
      subDate: {},
      applicationDate: {},
      payRate: {},
      otRate: {},
      otherRate: {},
      prefAM: {},
      prefMidDay: {},
      prefPM: {},
      prefOvernight: {},
      prefWeekend: {},
      prefNonSchool: {},
      regHoursWeek: {},
      regHoursDay: {},
      comments: {},
      indicators: {},
      totalFieldTrips: {},
      totalFieldTripHours: {},
      active: {},
    },
  },
  computed: {
    ...mapGetters('location', ['locations']),
    ...mapGetters('user', ['me', 'userEmails', 'canEditSetupTables']),
    ...mapGetters('app', ['isRoutingClient']),
    ...mapGetters('driver', ['drivers']),
    ...mapGetters('vehicle', ['vehiclesById']),
    ...mapGetters('assignment', ['assignments']),
    newDriver() {
      return {
        firstName: '',
        lastName: '',
        email: '',
        zone: this.config.defaultZone || '',
        baseLocationId: 0,
        secondaryLocationId: 0,
        assignedVehicleIds: 0,
        vehiclePreference: 0,
        designation: '',
        trips: 0,
        hours: 0,
        position: '',
        classCD: '',
        fulltime: true,
        localId: '',
        SSN: '',
        dob: '',
        hireAt: '',
        serviceTime: '',
        retirement: '',
        tempDate: '',
        subDate: '',
        applicationDate: '',
        payRate: 0,
        otRate: 0,
        otherRate: 0,
        prefAM: null,
        prefMidDay: null,
        prefPM: null,
        prefOvernight: null,
        prefWeekend: null,
        prefNonSchool: null,
        regHoursWeek: 0,
        regHoursDay: 0,
        comments: '',
        indicators: [],
        totalFieldTrips: 0,
        totalFieldTripHours: 0,
        active: true,
      };
    },
    upcomingAssignments() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      let upcoming = [];

      upcoming = this.assignments.find((item) => {
        const pickupDate = new Date(item.vehPickupDate);
        return item.driverId === this.id && pickupDate > today;
      });
      return upcoming;
    },
    secondaryLocationLabel() {
      if (this.config.secondaryLocLabel && this.config.secondaryLocLabel.length) return this.config.secondaryLocLabel;
      else return 'Secondary Location';
    },
    assignedVehicles() {
      return this.value.assignedVehicleIds?.map((e) => this.vehiclesById[e].name).join(', ') || ' ';
    },
  },
  methods: {
    ...mapActions('driverLog', [SAVE_DRIVER_LOG]),
    driverExists(model) {
      const { id, firstName, lastName } = model;
      return this.drivers.some((driver) => {
        const formattedFirstName = driver.firstName.trim().toLowerCase();
        const formattedLastName = driver.lastName.trim().toLowerCase();
        const inputFirstName = firstName.trim().toLowerCase();
        const inputLastName = lastName.trim().toLowerCase();
        return id !== driver.id && formattedFirstName === inputFirstName && formattedLastName === inputLastName;
      });
    },
    async cancel(fromSave) {
      let ok = true;
      const changesMade = this.compareValues();
      if (!fromSave && changesMade)
        ok = await this.$myconfirm('Are you sure you want to close this form? Your changes will not be saved.');
      if (ok) {
        this.form = this.newDriver;
        if (this.$refs.aform) this.$refs.aform.clear();
        this.$emit('closeForm');
      }
    },
    addAddress() {
      this.addresses.push({});
    },
    deleteAddress(index) {
      if (this.addresses[index].id) this.addresses[index].deleted = !this.addresses[index].deleted;
      else this.addresses.splice(index, 1);
      this.addresses.push({});
      this.addresses.pop();
    },
    handleInputAddress(address, index) {
      this.addresses[index] = address;
    },
    handlePrimaryAddress(index, setTo) {
      this.addresses.forEach((e) => (e.isPrimary = false));
      this.addresses[index].isPrimary = setTo;
    },
    addContact() {
      this.contacts.push({});
    },
    deleteContact(index) {
      if (this.contacts[index].id) this.contacts[index].deleted = !this.contacts[index].deleted;
      else this.contacts.splice(index, 1);
      this.contacts.push({});
      this.contacts.pop();
    },
    handleInputContact(contact, index) {
      this.contacts[index] = contact;
    },
    handlePrimaryContact(index, setTo) {
      this.contacts.forEach((e) => (e.isPrimary = false));
      this.contacts[index].isPrimary = setTo;
    },
    onSubmit() {
      if (this.driverExists(this.$v.form.$model)) {
        this.$myalert.error('Driver already exists');
        return;
      }
      if (this.$v.form.$invalid) {
        this.$v.form.$touch();
        this.$myalert.error('Please complete all required fields');
        return;
      }
      this.$emit('submit', {
        id: this.id,
        ...this.$v.form.$model,
        addresses: this.addresses,
        contacts: this.contacts,
      });
      this.saved = true;
    },
    async postDriverLog() {
      if (this.driverLogNotes.length) {
        try {
          const r = await this.saveDriverLog({ driverId: this.id, message: this.driverLogNotes });
          if (r) {
            this.$myalert.success('Comment saved to Driver Log');
            this.driverLogNotes = '';
          }
        } catch (error) {
          this.$myalert.error(error.message);
        }
      } else this.$myalert.error('Please enter a comment first');
    },
    async hide() {
      const message = this.value.hidden
        ? `Are you sure you want to unhide ${this.value.firstName} ${this.value.lastName}?`
        : `Are you sure you want to hide ${this.value.firstName} ${this.value.lastName}? The Driver will be unavailable in EZActivityTrips but it will not effect EZRouting.`;
      const ok = await this.$myconfirm(message);
      if (!ok) return;
      this.$emit('submit', {
        id: this.id,
        ...this.$v.form.$model,
        addresses: this.addresses,
        contacts: this.contacts,
        oldVehicle:
          this.value.assignedVehicleId != this.$v.form.assignedVehicleId.$model
            ? this.$v.form.assignedVehicleId.$model
            : -1,
        hidden: !this.value.hidden,
      });
      this.saved = true;
    },
    convertDateFormat(dateTimeStr) {
      const date = new Date(dateTimeStr);

      let month = (date.getMonth() + 1).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');
      let year = date.getFullYear();

      let hours = date.getHours();
      let minutes = date.getMinutes().toString().padStart(2, '0');

      let ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12;

      return `${month}-${day}-${year} ${hours}:${minutes} ${ampm}`;
    },
    limiter(e, max, item) {
      if (e.length > max) {
        this.$myalert.error(`You may only select up to ${max} ${item}${max > 1 ? 's' : ''}`);
        e.pop();
      }
    },
    handleUserSelect(users, field) {
      this.$v.form[field].$model = users;
      this.$v.form.$touch();
    },
    compareValues() {
      if (!this.id && (this.$v.form.firstName.$model || this.$v.form.lastName.$model)) return true;
      if (!this.id) return false;
      if (
        this.$v.form.firstName.$model != this.value.firstName ||
        this.$v.form.lastName.$model != this.value.lastName ||
        this.$v.form.email.$model != this.value.email ||
        this.$v.form.zone.$model != this.value.zone ||
        this.$v.form.baseLocationId.$model != this.value.baseLocationId ||
        this.$v.form.secondaryLocationId.$model != this.value.secondaryLocationId ||
        this.$v.form.vehiclePreference.$model != this.value.vehiclePreference ||
        this.$v.form.designation.$model != this.value.designation ||
        this.$v.form.trips.$model != this.value.trips ||
        this.$v.form.hours.$model != this.value.hours ||
        this.$v.form.position.$model != this.value.position ||
        this.$v.form.classCD.$model != this.value.classCD ||
        this.$v.form.fulltime.$model != this.value.fulltime ||
        this.$v.form.localId.$model != this.value.localId ||
        this.$v.form.SSN.$model != this.value.SSN ||
        this.$v.form.dob.$model != this.value.dob ||
        this.$v.form.hireAt.$model != this.value.hireAt ||
        this.$v.form.serviceTime.$model != this.value.serviceTime ||
        this.$v.form.retirement.$model != this.value.retirement ||
        this.$v.form.tempDate.$model != this.value.tempDate ||
        this.$v.form.subDate.$model != this.value.subDate ||
        this.$v.form.applicationDate.$model != this.value.applicationDate ||
        this.$v.form.payRate.$model != this.value.payRate ||
        this.$v.form.otRate.$model != this.value.otRate ||
        this.$v.form.otherRate.$model != this.value.otherRate ||
        this.$v.form.prefAM.$model != this.value.prefAM ||
        this.$v.form.prefMidDay.$model != this.value.prefMidDay ||
        this.$v.form.prefPM.$model != this.value.prefPM ||
        this.$v.form.prefOvernight.$model != this.value.prefOvernight ||
        this.$v.form.prefWeekend.$model != this.value.prefWeekend ||
        this.$v.form.prefNonSchool.$model != this.value.prefNonSchool ||
        this.$v.form.regHoursWeek.$model != this.value.regHoursWeek ||
        this.$v.form.regHoursDay.$model != this.value.regHoursDay ||
        this.$v.form.comments.$model != this.value.comments ||
        this.$v.form.indicators.$model != this.value.indicators ||
        this.$v.form.totalFieldTrips.$model != this.value.totalFieldTrips ||
        this.$v.form.totalFieldTripHours.$model != this.value.totalFieldTripHours ||
        this.$v.form.active.$model != this.value.active ||
        JSON.stringify(this.address) != JSON.stringify(this.value.address) ||
        JSON.stringify(this.contacts) != JSON.stringify(this.value.contacts)
      )
        return true;
      return false;
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function (value) {
        this.id = value.id;
        if (value.id && this.saved) {
          this.saved = false;
          this.cancel(true);
        } else {
          this.form = { ...this.newDriver, ...value };
          this.addresses = value.addresses ? [...value.addresses] : [];
          this.contacts = value.contacts ? [...value.contacts] : [];
          this.contacts.forEach((e) => {
            if (e.emails && e.emails.length) {
              e.email = e.emails[0] ? e.emails[0].email : '';
              delete e.emails;
            }
            if (e.phones && e.phones.length) {
              e.phone = e.phones[0] ? e.phones[0].phone : '';
              e.ext = e.phones[0] ? e.phones[0].ext : '';
              e.canText = e.phones[0] ? e.phones[0].canText : true;
              delete e.phones;
            }
          });
        }
      },
    },
  },
};
</script>
